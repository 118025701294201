.registerCondition {
  font-size: 15px;
  line-height: 20px;
  color: #262525;
}

.RegisterAccount {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  margin-top: 21px;
}
.RegisterName {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.RegisterSignIn {
  color: #0087ac;
  margin-left: 3px;
  display: inline-block;
  cursor: pointer;
}
.RegisterFromData {
  width: 100%;
  background-color: #f9f3f3;
  border: #f9f3f3;
  border-radius: 30px;
  padding: 19px 0px 19px 30px;
  margin-top: 20px;
}

.formError {
  color: rgb(177, 0, 0);
  font-size: 13px;
  margin-left: 10px;
  margin-top: 10px;
}

.dataRegister {
  width: 100%;
}

.loginFromData {
  width: 100%;
  background-color: #f9f3f3;
  border: #f9f3f3;
  border-radius: 30px;
  padding: 14px 0px 14px 25px;
  margin-top: 20px;
}

/*======================== Responsive ====================*/

@media only screen and (max-width: 767px) {
  .RegisterName {
    display: block;
  }
  .registerForm .form-check {
    text-align: left;
  }
}
@media only screen and (max-width: 640px) {
  .signUpText {
    font-size: 26px;
    margin: 0;
  }
  .login h5 {
    text-align: center;
  }
}
