.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #0087ac;
  box-shadow: 13px 4px 16px rgba(0, 0, 0, 0.15);
}
.sidebar__logo {
  padding: 42px 25px;
}
.sidebar__menu {
  position: relative;
}
.sidebar__menu__item {
  display: flex;
  align-items: center;
  place-content: flex-start;
  padding: 14px 36px 14px 25px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.sidebar__menu__item__icon {
  margin-right: 13px;
}
.sidebar_title {
  display: flex;
  align-items: center;
  place-content: flex-start;
  font-weight: 400;
  font-size: 20px;
  padding: 14px 13px 14px 13px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sidebar__menu__item:hover {
  color: #7be3ff;
}

/*============================= Responsive ===================*/

@media only screen and (max-width: 1199px) {
  .sidebar__menu__item {
    font-size: 17px;
  }
  .sidebar__logo {
    padding: 28px 25px;
  }
}
