.profilePhoto {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 47px 0px;
  box-shadow: 0px 4px 14px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.profilePhoto img {
  border-radius: 60%;
}
.profile h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.changeButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 20px;
  background: #0087ac;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: none;
  padding: 19px 27px;
}

.changeButton .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profileMain {
  background: #fff;
  padding: 20px 40px 40px 25px;
  box-shadow: 0px 4px 14px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 33px;
  position: relative;
  border-radius: 10px;
}
.profileMain .tableSearch_Billing {
  padding-bottom: 14px;
  border-bottom: 1px solid #d9d9d9;
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
}
.tableSearch_Billing:before {
  content: " ";
  position: absolute;
  top: 22px;
  left: 0;
  bottom: 0;
  background-image: url("../../image/rectangle.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 5px;
  height: 19px;
}

.profileFrom {
  padding-top: 20px;
}

.profileFrom label {
  margin-left: 10px;
  padding-top: 20px;
  font-size: 19px;
}
/*============================= Responsive ===================*/

@media only screen and (max-width: 810px) {
  .profileMain {
    padding: 18px 19px 40px 18px;
  }
}
@media only screen and (max-width: 576px) {
  .profilePhoto {
    margin-bottom: 32px;
  }
  .profile h1 {
    font-size: 28px;
  }
}
