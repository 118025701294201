.page_wrapper {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0 15px;
}

.forgot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 26px);
  padding: 20px 0;
}

.formError {
  color: rgb(177, 0, 0);
  font-size: 13px;
  margin-left: 10px;
  margin-top: 10px;
}

h1 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 40px;
  margin-top: 10px;
}
h5 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.forgotForm {
  width: 100%;
  max-width: 650px;
  /* margin: 0 auto; */
  margin-top: 35px;
  background: #ffffff;
  box-shadow: 0px 4px 14px 10px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.forgotButtonDiv {
  text-align: center;
  margin-top: 18px;
}
.forgotButton {
  background: #0087ac;
  border: #0087ac;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: #fff;
  padding: 12px 45px;
  cursor: pointer;
}
.forgotButton:hover {
  background: #000000;
}
.loginFromData {
  width: 100%;
  background-color: #f9f3f3;
  border: #f9f3f3;
  border-radius: 30px;
  padding: 14px 0px 14px 25px;
  margin-top: 20px;
}

.err {
  text-align: center;
  color: #FF0000;
}

span {
  position: relative;
  bottom: 50px;
  left: 340px;
  color: #208ba8;
  font-size: 15px;
  line-height: 20px;
}

h2 {
  font-size: 25px;
  color: #000000;
  text-align: center;
}

.forgotAccount {
  text-align: center;
  font-size: 15px;
  text-decoration-line: underline;
  cursor: pointer;
}



/*==================================== Responsive =========================*/

@media only screen and (max-width: 575px) {
  .forgot h1 {
    font-size: 32px;
  }
  .forgot h5 {
    font-size: 16px;
  }
  .forgotForm {
    text-align: center;
  }
  .forgotForm span {
    position: static;
  }
  .forgotButtonDiv {
    margin-top: 18px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 420px) {
  .forgotForm {
    padding: 15px;
  }
  
  .forgot svg {
    width: 65px;
    height: 65px;
  }
  .forgot h1 {
    font-size: 28px;
  }
  .forgot h5 {
    font-size: 14px;
  }
}
