.addFitbit {
  padding: 70px 200px;
}

.addFitbit_Data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 14px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 30px 30px 100px 30px;
}

.addFitbit_Data h1 {
  color: #4e4444;
  margin-bottom: 30px;
}

.addFitbit_Data .buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}

.addFitbit_Data button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

.addFitbit_Data .btn1 {
  background: #0087ac;
  padding: 20px 5px;
  margin-top: 2em;
  margin-bottom: 15px;
}

.addFitbit_Data .btn1:hover {
  background: #005a74;
}

.addFitbit_Data .btn2:hover {
  background: #262550;
}

.addFitbit_Data .btn2 {
  background: #5453a8;
  margin-bottom: 30px;
  padding: 20px 0px;
}

.addFitbit_Data p {
  text-decoration: underline;
  font-size: 14px;
  margin: 5px;
}

@media only screen and (max-width: 802px) {
  .addFitbit {
    padding: 70px 100px;
  }
}

@media only screen and (max-width: 602px) {
  .addFitbit {
    padding: 70px 50px;
  }
}

@media only screen and (max-width: 502px) {
  .addFitbit_Data {
    padding: 30px 10px 100px 10px;
  }
}

@media only screen and (max-width: 462px) {
  .addFitbit_Data h1 {
    font-size: 30px;
  }

  .addFitbit_Data .buttons {
    max-width: 200px;
  }
}

@media only screen and (max-width: 377px) {
  .addFitbit {
    padding: 40px 25px;
  }
}

@media only screen and (max-width: 377px) {
  .addFitbit {
    padding: 40px 25px;
  }
}

@media only screen and (max-width: 327px) {
  .addFitbit_Data h1 {
    font-size: 25px;
  }
}