* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
::-moz-selection {
  background-color: #113e65;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #113e65;
  color: #fff;
  text-shadow: none;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif !important;
  background: #E3F2FF;
}
.container:after,
.container:before {
  content: " ";
  display: table;
}
.container:after {
  clear: both;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
figure {
  margin: 0;
  padding: 0;
}
img {
  margin: 0;
  padding: 0;
  border: none;
  max-width: 100%;
  height: auto !important;
  display: block;
}

a,
button,
input {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a {
  text-decoration: none !important;
}

.SilderDivider {
  padding: 28px 45px 0px 312px;
}
.SilderDivider .sidebar .closeBtn {
  display: none;
}
/*================================ Responsive =======================*/
@media only screen and (max-width: 1199px) {
  .SilderDivider {
    padding: 28px 35px 0px 275px;
}
}
@media only screen and (max-width: 1024px) {
  .SilderDivider {
    padding: 100px 0 0px;
  }
  .SilderDivider .sidebar {
    left: -100%;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .SilderDivider .sidebar.active_nav{
    left: 0;
  }
  .SilderDivider .top_part {
    padding: 15px 12px 15px 55px;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: #E3F2FF;
    box-shadow: 0px 4px 12px 5px rgba(0, 0, 0, 0.02);
}
.top_part .mobile_menu{
  display: block;
  left: 8px;
}
.SilderDivider .sidebar .closeBtn {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 25px;
    display: block;
    cursor: pointer;
}  
}

@media only screen and (max-width: 575px) {
  .top_part .mobile_menu {
      top: 10px;
      bottom: auto;
  }  
  .SilderDivider .top_part {
    padding: 15px 12px 70px 55px;
}
.SilderDivider .top_part .search_form {
  position: absolute;
  max-width: 100%;
  left: 0;
  width: 100%;
  top: 55px;
  padding: 0 12px;
}
.SilderDivider .top_part .search_form input[type="submit"] {
  right: 12px;
} 
.SilderDivider {
  padding: 130px 0 0px;
}
.SilderDivider .top_part .row .col-6{
  width: 100%;
}
}
