.table_data {
  width: 100%;
  display: block;
  margin: 0 0 40px;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 4px 14px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.mainTable h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 35px;
}
.pagination_and_search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  position: relative;
}

.pagination_and_search .show_result {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination_and_search .show_result .show_arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination_and_search .show_result .show_img {
  margin-left: 70px;
}
.pagination_and_search .show_result p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #817b7b;
  margin: 0;
  margin-right: 7px;
}

.pagination_and_search:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-image: url("../../image/rectangle.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 5px;
  height: 19px;
}
.table_data table {
  width: 100%;
}
.table_data table tr th {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 12px;

  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #000000;
}
.table_data table tr td {
  border-bottom: 1px solid #ccc;
  padding: 12px;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #504f4f;
}
.table_data table tr td img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.paginatin_sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
.paginatin_sec p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #000000;
  margin: 0;
}
.paginatin_sec ul li {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}
.paginatin_sec ul li .page {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 100%;
  color: #656565;
  font-size: 12px;
  display: inline-block;
}
.paginatin_sec ul li .page.current,
.paginatin_sec ul li .page:hover {
  background-color: #0087ac;
  color: #fff;
}

.progress_bar_file {
  width: 100%;
  display: block;
  margin: 35px 0px 0px;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
}

.progress_heading {
  width: 100%;
  display: block;
  margin: 0;
  padding: 13px;
  border-bottom: 1px solid #d9d9d9;
  position: relative;
}
.progress_heading:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-image: url("../../image/rectangle.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 5px;
  height: 19px;
}
.progress_heading h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
}

.gap .tableName {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #322e2e;
}
.tableProgress .tableText {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #000000;
}

.progress_list {
  width: 100%;
  display: block;
  margin: 0;
  padding: 13px;
}
.progress_list .gap {
  width: 100%;
  display: block;
  margin: 0 0 16px;
  padding: 0;
}
.progress_list .tableProgress .progress {
  height: 7px;
  border-radius: 10px;
}

/*============================= Responsive ===================*/

@media only screen and (max-width: 810px) {
  .tableProgress .tableText {
    font-size: 13px;
  }
  .table_data table tr th {
    font-size: 13px;
  }
  .table_data table tr td {
    font-size: 13px;
  }
  .paginatin_sec p {
    font-size: 13px;
  }
  .paginatin_sec ul li .page {
    font-size: 11px;
  }
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 456px) {
  .table_area {
    overflow-x: scroll;
  }
}
