.top_part {
  width: 100%;
  display: block;
  margin: 0 0 30px;
  /* padding: 0 0 0 70px; */
  padding: 0;
  position: relative;
}

.logoutButton {
  background: #0087ac;
  border: #0087ac;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  color: #fff;
  padding: 12px 45px;
  cursor: pointer;
}
.mobile_menu {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  display: none;
}
.mobile_menu img {
  width: 40px;
}
.search_form {
  width: 100%;
  max-width: 380px;
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
}
.top_part .search_form input {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);
  border-radius: 30px;
  width: 100%;
  padding: 15px 100px 15px 26px;
  border: none;
}

.top_part .custom_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.search_form .SearchDropdown {
  position: absolute;
  top: 0;
  right: 12px;
  display: flex;
  align-items: center;
  height: 52px;
}
.dropDown {
  border: 0px;
  border-right: 1px solid #d9d9d9;
  padding-right: 14px;
  background: none;
  position: relative;
}
.top_part .right_info .number_count {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #075b72;
  position: absolute;
  top: -10px;
  left: 10px;
  color: #fff;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
}

.SearchDropdown .SearchButton {
  border: none;
  background: none;
  padding-left: 14px;
}

.right_info {
  text-align: right;
}
.right_info > ul > li {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
.right_info > ul > li + li {
  margin-left: 25px;
  position: relative;
}
.right_info > ul > li + li:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -15px;
  width: 1px;
  height: 60%;
  background-color: #958c8c;
}
.right_info > ul > li span {
  display: inline-block;
  vertical-align: middle;
  position: static;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #504f4f;
  margin-right: 17px;
}
.right_info > ul > li img {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}
