@import "react-datepicker/dist/react-datepicker.css";

.DashboardHead {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.Dashboard_button {
  display: inline-block;
  background: #0087ac;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
/*  margin-top: 50px;*/
  margin-bottom: 50px;
  margin-right: 15px;
  padding: 8px 20px 8px 20px;
  height: auto;
  width: auto;
}

.datePicker {
  font-size: 8px;
  margin-left: 10px;  
  overflow: inherit;
  padding: 8px 80px 8px 0px;
}

.check_box_custom {
  font-size: 16px;
  margin-top: 5px;  
  margin-left: 5px;
  margin-right: 5px;
}

.customReport {
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  width: 370px;
  display: flex;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
   font-size: 15px;
   width: 100px;
   margin-top: 4px;
}

.react-datepicker-popper .react-datepicker__triangle {
}

.react-datepicker__navigation {
  overflow: inherit;
}

.react-datepicker__navigation-icon {
  width: 100%;
}

.react-datepicker__navigation-icon--previous::before {
  left: -330px;
  border-color: #0087ac;
}

.react-datepicker__navigation-icon--next::before {
  right: 10px;
  left: auto;
  border-color: #0087ac;
}

.DashboardHead button:hover{
  background-color: #000000;
}

.DashboardHead h2 {
  font-size: 25px;
  line-height: 35px;
}
.calculated_value{
  width: 100%;
  display: block;
  margin: 0 0 40px;
  padding: 0;
}
.calculated_value .time {
  background: #fff;
  padding: 30px 25px;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
  height: 100%;
  border-radius: 10px;
}

.calculated_value .time p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 15px;
}

.calculated_value .timeData {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;
}

.calculated_value .timeData h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-left: 15px;
}
.calculated_value .progress {
  height: 7px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 0 10px;
}

.chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.chartAreaWrapper {
  width: 600px;
  overflow-x: scroll;
}

.graph {
  width: 100%;
  display: block;
  margin:0;
  padding:0;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}

.graph h4 {
  font-weight: 500;
  font-size: 19px;
  line-height: 26px;
  margin-bottom: 18px;
}


.loading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 20px 20px 20px;
  font-size: 40px;
}

.practice_name  {
  display: inline-block;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 0px;
  font-size: 22px;
}


/*============================= Responsive ===================*/
@media only screen and (max-width:1680px){
}
@media only screen and (max-width:1440px){
}
@media only screen and (max-width:1370px){
}

@media only screen and (max-width:1199px){
  .calculated_value .timeData h6 {
    font-size: 16px;
    margin-left: 8px;
}  
}
@media only screen and (max-width:1024px){
}
@media only screen and (max-width:991px){
  .calculated_value .row .col-lg-3{
    margin-bottom: 20px;
  }
  .calculated_value {
    width: 100%;
    display: block;
    margin: 0 0 15px;
    padding: 0;
}  
}
@media only screen and (max-width:840px){
}
@media only screen and (max-width:768px){
}
@media only screen and (max-width:640px){ 
}
@media only screen and (max-width:575px){
  .DashboardHead h2 {
    font-size: 25px;
} 
.DashboardHead button {
  padding: 12px 20px;
  font-size: 13px;
} 
}
@media only screen and (max-width:420px){ 
  .DashboardHead h2 {
    font-size: 20px;
}  
}
@media only screen and (max-width:360px){
}

.dropdown-menu1 {
  list-style: none;
  padding-top: 10px;
  margin: 0;
}

.dropdown-item {
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #ffffff;
  border-radius: 0px;
  border: 1px solid transparent;
}

.dropdown-item:hover {
  background-color: #fffafa4f;
  border: 1px solid #10749B;
}